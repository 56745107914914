/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Note = {
  key?: string;
  type?: Note.type;
  priority?: number;
  url?: string;
  routeIdxFrom?: number;
  routeIdxTo?: number;
  txtN?: string;
  txtL?: string;
  txtS?: string;
};

export namespace Note {
  export enum type {
    U = 'U',
    A = 'A',
    I = 'I',
    R = 'R',
    H = 'H',
    M = 'M',
    C = 'C',
    D = 'D',
    B = 'B',
    Q = 'Q',
    L = 'L',
    N = 'N',
    O = 'O',
    P = 'P',
    S = 'S',
    V = 'V',
    X = 'X',
    Z = 'Z',
    Y = 'Y',
    K = 'K',
    G = 'G',
    W = 'W',
    ED = 'ED',
    TAR = 'TAR',
    FN = 'FN',
    TLN = 'TLN',
    LNC = 'LNC',
  }
}
