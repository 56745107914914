/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DisplayContentStructure } from './DisplayContentStructure';
import type { VehicleTypeStructure } from './VehicleTypeStructure';

export type ConnectionStructure = {
  connectionRef: string;
  connectionType: ConnectionStructure.connectionType;
  displayContent: DisplayContentStructure;
  departureStopPointRef: string;
  platform: string;
  connectionState: ConnectionStructure.connectionState;
  transportmode?: VehicleTypeStructure;
  expectedDepartureTime?: string;
};

export namespace ConnectionStructure {
  export enum connectionType {
    INTERCHANGE = 'INTERCHANGE',
    PROTECTED_CONNECTION = 'PROTECTED_CONNECTION',
  }

  export enum connectionState {
    CONNECTION_BROKEN = 'CONNECTION_BROKEN',
    CONNECTION_OK = 'CONNECTION_OK',
    NO_INFORMATION_AVAILABLE = 'NO_INFORMATION_AVAILABLE',
  }
}
