/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FreqType } from './FreqType';
import type { GisRef } from './GisRef';
import type { GisRouteType } from './GisRouteType';
import type { JourneyDetail } from './JourneyDetail';
import type { JourneyDetailRef } from './JourneyDetailRef';
import type { JourneyType } from './JourneyType';
import type { Messages } from './Messages';
import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';
import type { OriginDestType } from './OriginDestType';
import type { Polyline } from './Polyline';
import type { PolylineGroup } from './PolylineGroup';
import type { ProductType } from './ProductType';
import type { ReferencedJourneyType } from './ReferencedJourneyType';
import type { ServiceDays } from './ServiceDays';
import type { Stops } from './Stops';
import type { TrafficMessageType } from './TrafficMessageType';

export type Leg = {
  duration?: string;
  Origin: OriginDestType;
  Destination: OriginDestType;
  JourneyOrigin?: OriginDestType;
  JourneyDestination?: OriginDestType;
  Notes?: Notes;
  JourneyDetailRef?: JourneyDetailRef;
  Freq?: FreqType;
  GisRef?: GisRef;
  GisRoute?: GisRouteType;
  Messages?: Messages;
  readonly TrafficMessage?: Array<TrafficMessageType>;
  JourneyStatus?: Leg.JourneyStatus;
  readonly Product?: Array<ProductType>;
  Polyline?: Polyline;
  PolylineGroup?: PolylineGroup;
  Stops?: Stops;
  readonly ServiceDays?: Array<ServiceDays>;
  JourneyDetail?: JourneyDetail;
  readonly ParallelJourney?: Array<JourneyType>;
  readonly Occupancy?: Array<OccupancyType>;
  readonly referencedJourney?: Array<ReferencedJourneyType>;
  idx: number;
  name?: string;
  addName?: string;
  number?: string;
  category?: string;
  type: string;
  cancelled?: boolean;
  partCancelled?: boolean;
  reachable?: boolean;
  redirected?: boolean;
  direction?: string;
  directionFlag?: string;
  dist?: number;
  hide?: boolean;
  psCtxArriveEarlier?: string;
  psCtxDepartLater?: string;
  recState?: Leg.recState;
  changeAssured?: boolean;
  uncertainDelay?: boolean;
};

export namespace Leg {
  export enum JourneyStatus {
    P = 'P',
    R = 'R',
    A = 'A',
    S = 'S',
  }

  export enum recState {
    U = 'U',
    C = 'C',
    P = 'P',
    N = 'N',
    O = 'O',
  }
}
