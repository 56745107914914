/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccessibilityStructure = {
  value?: AccessibilityStructure.value;
};

export namespace AccessibilityStructure {
  export enum value {
    UNKNOWN = 'UNKNOWN',
    FALSE = 'FALSE',
    TRUE = 'TRUE',
  }
}
