/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TripMonitoringParamStructure = {
  severity?: TripMonitoringParamStructure.severity;
  minimumDelayChangeThreshold?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  acceptThirdPartyInformation?: boolean;
  includeAlternatives?: boolean;
};

export namespace TripMonitoringParamStructure {
  export enum severity {
    PTI_26_0 = 'PTI_26_0',
    UNKNOWN = 'UNKNOWN',
    PTI_26_1 = 'PTI_26_1',
    VERY_SLIGHT = 'VERY_SLIGHT',
    PTI_26_2 = 'PTI_26_2',
    SLIGHT = 'SLIGHT',
    PTI_26_3 = 'PTI_26_3',
    NORMAL = 'NORMAL',
    PTI_26_4 = 'PTI_26_4',
    SEVERE = 'SEVERE',
    PTI_26_5 = 'PTI_26_5',
    VERY_SEVERE = 'VERY_SEVERE',
    PTI_26_6 = 'PTI_26_6',
    NO_IMPACT = 'NO_IMPACT',
    PTI_26_255 = 'PTI_26_255',
    UNDEFINED = 'UNDEFINED',
  }
}
