/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionsStructure } from './ExtensionsStructure';

export type IndividualTripParamStructure = {
  noSingleStep?: boolean;
  noStairs?: boolean;
  noEscalator?: boolean;
  noElevator?: boolean;
  noRamp?: boolean;
  numberOfResults?: number;
  numberOfResultsBefore?: number;
  numberOfResultsAfter?: number;
  ignoreRealtimeData?: boolean;
  immediateTripStart?: boolean;
  algorithmType?: IndividualTripParamStructure.algorithmType;
  banMotorways?: boolean;
  banTollRoads?: boolean;
  banFerries?: boolean;
  banTunnels?: boolean;
  banBridges?: boolean;
  allowUnpavedRoads?: boolean;
  includeTrackSections?: boolean;
  includeLegProjection?: boolean;
  includeTurnDescription?: boolean;
  includeAccessibility?: boolean;
  extension?: ExtensionsStructure;
};

export namespace IndividualTripParamStructure {
  export enum algorithmType {
    FASTEST = 'FASTEST',
    SHORTEST = 'SHORTEST',
    BEAUTIFUL = 'BEAUTIFUL',
    OPTIMAL = 'OPTIMAL',
    ECONOMIC = 'ECONOMIC',
  }
}
