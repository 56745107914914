/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { RouteElement } from './RouteElement';

export type RoutePart = {
  mode: RoutePart.mode;
  elements?: Array<RouteElement>;
};

export namespace RoutePart {
  export enum mode {
    WALK = 'WALK',
    BIKE = 'BIKE',
    PT = 'PT',
    CAR = 'CAR',
  }
}
