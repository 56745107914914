/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type ManualAction = {
  actionStatus?: ManualAction.actionStatus;
  description?: NaturalLanguageStringStructure;
  actionData?: Array<ActionDataStructure>;
};

export namespace ManualAction {
  export enum actionStatus {
    OPEN = 'OPEN',
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED',
  }
}
