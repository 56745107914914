/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessibilityAssessmentStructure } from './AccessibilityAssessmentStructure';
import type { AffectedModesStructure } from './AffectedModesStructure';
import type { ConnectionLinks } from './ConnectionLinks';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { LocationStructure } from './LocationStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { ZoneRefStructure } from './ZoneRefStructure';

export type AffectedStopPointStructure = {
  stopPointRef?: StopPointRefStructure;
  privateRef?: string;
  stopPointName?: NaturalLanguageStringStructure;
  stopPointType?: AffectedStopPointStructure.stopPointType;
  location?: LocationStructure;
  affectedModes?: AffectedModesStructure;
  placeRef?: ZoneRefStructure;
  placeName?: NaturalLanguageStringStructure;
  accessibilityAssessment?: AccessibilityAssessmentStructure;
  connectionLinks?: ConnectionLinks;
  extensions?: ExtensionsStructure;
};

export namespace AffectedStopPointStructure {
  export enum stopPointType {
    PTI_17_0 = 'PTI_17_0',
    UNKNOWN = 'UNKNOWN',
    PTI_17_1 = 'PTI_17_1',
    PLATFORM_NUMBER = 'PLATFORM_NUMBER',
    PTI_17_2 = 'PTI_17_2',
    TERMINAL_GATE = 'TERMINAL_GATE',
    PTI_17_3 = 'PTI_17_3',
    FERRY_BERTH = 'FERRY_BERTH',
    PTI_17_4 = 'PTI_17_4',
    HARBOUR_PIER = 'HARBOUR_PIER',
    PTI_17_5 = 'PTI_17_5',
    LANDING_STAGE = 'LANDING_STAGE',
    PTI_17_6 = 'PTI_17_6',
    BUS_STOP = 'BUS_STOP',
    PTI_17_255 = 'PTI_17_255',
    UNDEFINED_BOOKING_INFORMATION = 'UNDEFINED_BOOKING_INFORMATION',
  }
}
