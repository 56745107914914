/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InternationalTextStructure } from './InternationalTextStructure';

export type RecommendationStructure = {
  recommendationId?: string;
  text?: InternationalTextStructure;
  type?: RecommendationStructure.type;
};

export namespace RecommendationStructure {
  export enum type {
    NEXT_SERVICE = 'NEXT_SERVICE',
    DIFFERENT_ROUTE = 'DIFFERENT_ROUTE',
    HOTEL = 'HOTEL',
    TAXI = 'TAXI',
    BUS = 'BUS',
    HELPDESK = 'HELPDESK',
    HOTLINE = 'HOTLINE',
    DRIVER = 'DRIVER',
    OTHER = 'OTHER',
  }
}
