/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type HealthEffect = {
  type?: HealthEffect.type;
  riskWithoutRoute?: number;
  riskWithRoute?: number;
  riskChange?: number;
};

export namespace HealthEffect {
  export enum type {
    COLON_CANCER = 'COLON_CANCER',
    DIABETES = 'DIABETES',
    HEART_DISEASE = 'HEART_DISEASE',
    STROKE = 'STROKE',
  }
}
