import { Box, css, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { PrintButtonGroup } from '@/components/PrintButtonGroup';
import { useIsPrinting } from '@/hooks/useIsPrinting';
import { transientOptions } from '@/utils/transient-options';

const Root = styled(
  Box,
  transientOptions,
)<{ $isPrinting: boolean }>(
  ({ $isPrinting, theme }) => css`
    display: flex;
    flex-direction: column;

    ${!$isPrinting &&
    css`
      ${theme.breakpoints.up('md')} {
        height: 100vh;
      }
    `}

    @page {
      size: A4;
      margin: 0;
    }

    @media print {
      height: 100%;
      min-height: 297mm;
      margin: 0;
      padding: 1cm;
      box-shadow: none;

      @root html,
      body {
        width: 210mm;
        height: 297mm;
      }
    }

    ${$isPrinting &&
    css`
      width: 210mm;
      min-height: 297mm;
      padding: 1cm;
      margin: ${theme.spacing(16)} auto ${theme.spacing(8)};
      border-radius: 3px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.35);
    `}
  `,
);

const Main = styled(
  Box,
  transientOptions,
)<{ $isPrinting: boolean }>(
  ({ theme, $isPrinting }) => css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${$isPrinting ? 'white' : theme.palette.secondary.main};
    justify-content: space-between;
  `,
);

export function PageLayout() {
  const { isPrinting } = useIsPrinting();

  return (
    <Root $isPrinting={isPrinting}>
      {isPrinting && <PrintButtonGroup />}
      <Header />
      <Main $isPrinting={isPrinting} component="main">
        <Outlet />
      </Main>
      {!isPrinting && <Footer />}
    </Root>
  );
}
