/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';
import type { GeneralAttributeStructure } from './GeneralAttributeStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { LocationRefStructure } from './LocationRefStructure';
import type { NavigationPathStructure } from './NavigationPathStructure';
import type { SituationFullRefStructure } from './SituationFullRefStructure';

export type InterchangeLegStructure = {
  attribute?: Array<GeneralAttributeStructure>;
  bufferTime?: Duration;
  continuousMode?: InterchangeLegStructure.continuousMode;
  duration?: Duration;
  extension?: any;
  interchangeMode?: InterchangeLegStructure.interchangeMode;
  legDescription?: InternationalTextStructure;
  legEnd?: LocationRefStructure;
  legStart?: LocationRefStructure;
  length?: number;
  navigationPath?: NavigationPathStructure;
  situationFullRef?: Array<SituationFullRefStructure>;
  timeWindowEnd?: string;
  timeWindowStart?: string;
  walkDuration?: Duration;
};

export namespace InterchangeLegStructure {
  export enum continuousMode {
    WALK = 'WALK',
    DEMAND_RESPONSIVE = 'DEMAND_RESPONSIVE',
    REPLACEMENT_SERVICE = 'REPLACEMENT_SERVICE',
  }

  export enum interchangeMode {
    WALK = 'WALK',
    PARK_AND_RIDE = 'PARK_AND_RIDE',
    BIKE_AND_RIDE = 'BIKE_AND_RIDE',
    CAR_HIRE = 'CAR_HIRE',
    BIKE_HIRE = 'BIKE_HIRE',
    PROTECTED_CONNECTION = 'PROTECTED_CONNECTION',
    GUARANTEED_CONNECTION = 'GUARANTEED_CONNECTION',
    REMAIN_IN_VEHICLE = 'REMAIN_IN_VEHICLE',
    CHANGE_WITHIN_VEHICLE = 'CHANGE_WITHIN_VEHICLE',
    CHECK_IN = 'CHECK_IN',
    CHECK_OUT = 'CHECK_OUT',
  }
}
