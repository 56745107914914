/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionsStructure } from './ExtensionsStructure';
import type { FaresParamStructure } from './FaresParamStructure';
import type { LineDirectionFilterStructure } from './LineDirectionFilterStructure';
import type { OperatorFilterStructure } from './OperatorFilterStructure';
import type { PtModeFilterStructure } from './PtModeFilterStructure';

export type TripParamStructure = {
  algorithmType?: TripParamStructure.algorithmType;
  bikeTransport?: boolean;
  extension?: ExtensionsStructure;
  faresParam?: FaresParamStructure;
  ignoreRealtimeData?: boolean;
  immediateTripStart?: boolean;
  includeAccessibility?: boolean;
  includeFares?: boolean;
  includeIntermediateStops?: boolean;
  includeLegProjection?: boolean;
  includeOperatingDays?: boolean;
  includeTrackSections?: boolean;
  includeTurnDescription?: boolean;
  interchangeLimit?: number;
  itModesToCover?: Array<'WALK' | 'CYCLE' | 'TAXI' | 'SELF_DRIVE_CAR' | 'OTHERS_DRIVE_CAR' | 'MOTORCYCLE' | 'TRUCK'>;
  levelEntrance?: boolean;
  lineFilter?: LineDirectionFilterStructure;
  noElevator?: boolean;
  noEscalator?: boolean;
  noRamp?: boolean;
  noSingleStep?: boolean;
  noStairs?: boolean;
  numberOfResults?: number;
  numberOfResultsAfter?: number;
  numberOfResultsBefore?: number;
  operatorFilter?: OperatorFilterStructure;
  ptModeFilter?: PtModeFilterStructure;
  walkSpeed?: number;
};

export namespace TripParamStructure {
  export enum algorithmType {
    FASTEST = 'FASTEST',
    MIN_CHANGES = 'MIN_CHANGES',
    LEAST_WALKING = 'LEAST_WALKING',
    LEAST_COST = 'LEAST_COST',
  }
}
