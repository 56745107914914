/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IconType } from './IconType';
import type { PolylineGroup } from './PolylineGroup';
import type { StopLocation } from './StopLocation';

export type TrafficMessageType = {
  icon?: IconType;
  location?: StopLocation;
  PolylineGroup?: PolylineGroup;
  id?: string;
  extId?: string;
  type?: TrafficMessageType.type;
  name?: string;
  desc?: string;
};

export namespace TrafficMessageType {
  export enum type {
    U = 'U',
    RCLM = 'RCLM',
    AC = 'AC',
    RW = 'RW',
    AT = 'AT',
    CO = 'CO',
    TRAFFIC_JAM = 'TRAFFIC_JAM',
    DELAY = 'DELAY',
    ROAD_CLOSED = 'ROAD_CLOSED',
    JUNCTION_CLOSED = 'JUNCTION_CLOSED',
    LANE_CLOSED = 'LANE_CLOSED',
    BURNING_VEHICLE = 'BURNING_VEHICLE',
    ACCIDENT = 'ACCIDENT',
    DANGER = 'DANGER',
    OBSTRUCTION = 'OBSTRUCTION',
    RAIL_ROAD_CROSSING = 'RAIL_ROAD_CROSSING',
    TRAFFIC_LIGHTS_DEFECT = 'TRAFFIC_LIGHTS_DEFECT',
    WEATHER = 'WEATHER',
    WEATHER_ICE = 'WEATHER_ICE',
    WEATHER_SNOW = 'WEATHER_SNOW',
    WEATHER_POOR_VISIBILITY = 'WEATHER_POOR_VISIBILITY',
    WEATHER_HAIL = 'WEATHER_HAIL',
    WEATHER_WIND = 'WEATHER_WIND',
    CONSTRUCTION_SITE = 'CONSTRUCTION_SITE',
  }
}
