/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OperatorRefStructure } from './OperatorRefStructure';
import type { WebLinkStructure } from './WebLinkStructure';

export type SharingServiceStructure = {
  operatorRef: OperatorRefStructure;
  name?: string;
  sharingModel?: SharingServiceStructure.sharingModel;
  timeBufferBefore?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  timeBufferAfter?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  infoURL?: WebLinkStructure;
};

export namespace SharingServiceStructure {
  export enum sharingModel {
    SINGLE_STATION_BASED = 'SINGLE_STATION_BASED',
    MULTIPLE_STATION_BASED = 'MULTIPLE_STATION_BASED',
    NON_STATION_BASED = 'NON_STATION_BASED',
  }
}
