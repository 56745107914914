/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessibilityAssessmentStructure } from './AccessibilityAssessmentStructure';
import type { AffectedInterchangeStructure } from './AffectedInterchangeStructure';
import type { AffectedModesStructure } from './AffectedModesStructure';
import type { ConnectionLinks } from './ConnectionLinks';
import type { Duration } from './Duration';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { LocationStructure } from './LocationStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { ZoneRefStructure } from './ZoneRefStructure';

export type AffectedCallStructure = {
  accessibilityAssessment?: AccessibilityAssessmentStructure;
  actualArrivalTime?: string;
  actualDepartureTime?: string;
  affectedInterchange?: Array<AffectedInterchangeStructure>;
  affectedModes?: AffectedModesStructure;
  aimedArrivalTime?: string;
  aimedDepartureTime?: string;
  aimedHeadwayInterval?: Duration;
  arrivalBoardingActivity?: AffectedCallStructure.arrivalBoardingActivity;
  arrivalPlatformName?: NaturalLanguageStringStructure;
  arrivalStatus?: AffectedCallStructure.arrivalStatus;
  callCondition?: AffectedCallStructure.callCondition;
  connectionLinks?: ConnectionLinks;
  departureBoardingActivity?: AffectedCallStructure.departureBoardingActivity;
  departurePlatformName?: NaturalLanguageStringStructure;
  departureStatus?: AffectedCallStructure.departureStatus;
  expectedArrivalTime?: string;
  expectedDepartureTime?: string;
  expectedHeadwayInterval?: Duration;
  extensions?: ExtensionsStructure;
  location?: LocationStructure;
  order?: number;
  placeName?: NaturalLanguageStringStructure;
  placeRef?: ZoneRefStructure;
  privateRef?: string;
  stopPointName?: NaturalLanguageStringStructure;
  stopPointRef?: StopPointRefStructure;
  stopPointType?: AffectedCallStructure.stopPointType;
  vehicleAtStop?: boolean;
  vehicleLocationAtStop?: LocationStructure;
};

export namespace AffectedCallStructure {
  export enum arrivalBoardingActivity {
    ALIGHTING = 'ALIGHTING',
    NO_ALIGHTING = 'NO_ALIGHTING',
    PASS_THRU = 'PASS_THRU',
  }

  export enum arrivalStatus {
    ON_TIME = 'ON_TIME',
    EARLY = 'EARLY',
    DELAYED = 'DELAYED',
    CANCELLED = 'CANCELLED',
    ARRIVED = 'ARRIVED',
    NO_REPORT = 'NO_REPORT',
  }

  export enum callCondition {
    PTI_15_0 = 'PTI_15_0',
    UNKNOWN = 'UNKNOWN',
    PTI_15_1 = 'PTI_15_1',
    START_POINT = 'START_POINT',
    PTI_15_2 = 'PTI_15_2',
    DESTINATION = 'DESTINATION',
    PTI_15_3 = 'PTI_15_3',
    STOP = 'STOP',
    PTI_15_4 = 'PTI_15_4',
    VIA = 'VIA',
    PTI_15_5 = 'PTI_15_5',
    NOT_STOPPING = 'NOT_STOPPING',
    PTI_15_6 = 'PTI_15_6',
    TEMPORARY_STOP = 'TEMPORARY_STOP',
    PTI_15_7 = 'PTI_15_7',
    TEMPORARILY_NOT_STOPPING = 'TEMPORARILY_NOT_STOPPING',
    PTI_15_8 = 'PTI_15_8',
    EXCEPTIONAL_STOP = 'EXCEPTIONAL_STOP',
    PTI_15_9 = 'PTI_15_9',
    ADDITIONAL_STOP = 'ADDITIONAL_STOP',
    PTI_15_10 = 'PTI_15_10',
    REQUEST_STOP = 'REQUEST_STOP',
    PTI_15_11 = 'PTI_15_11',
    FRONT_TRAIN_DESTINATION = 'FRONT_TRAIN_DESTINATION',
    PTI_15_12 = 'PTI_15_12',
    REAR_TRAIN_DESTINATION = 'REAR_TRAIN_DESTINATION',
    PTI_15_13 = 'PTI_15_13',
    THROUGH_SERVICE_DESTINATION = 'THROUGH_SERVICE_DESTINATION',
    PTI_15_14 = 'PTI_15_14',
    NOT_VIA = 'NOT_VIA',
    PTI_15_15 = 'PTI_15_15',
    ALTERED_START_POINT = 'ALTERED_START_POINT',
    PTI_15_16 = 'PTI_15_16',
    ALTERED_DESTINATION = 'ALTERED_DESTINATION',
    PTI_15_255 = 'PTI_15_255',
    UNDEFINED_ROUTE_POINT = 'UNDEFINED_ROUTE_POINT',
  }

  export enum departureBoardingActivity {
    BOARDING = 'BOARDING',
    NO_BOARDING = 'NO_BOARDING',
    PASS_THRU = 'PASS_THRU',
  }

  export enum departureStatus {
    ON_TIME = 'ON_TIME',
    EARLY = 'EARLY',
    DELAYED = 'DELAYED',
    CANCELLED = 'CANCELLED',
    ARRIVED = 'ARRIVED',
    NO_REPORT = 'NO_REPORT',
  }

  export enum stopPointType {
    PTI_17_0 = 'PTI_17_0',
    UNKNOWN = 'UNKNOWN',
    PTI_17_1 = 'PTI_17_1',
    PLATFORM_NUMBER = 'PLATFORM_NUMBER',
    PTI_17_2 = 'PTI_17_2',
    TERMINAL_GATE = 'TERMINAL_GATE',
    PTI_17_3 = 'PTI_17_3',
    FERRY_BERTH = 'FERRY_BERTH',
    PTI_17_4 = 'PTI_17_4',
    HARBOUR_PIER = 'HARBOUR_PIER',
    PTI_17_5 = 'PTI_17_5',
    LANDING_STAGE = 'LANDING_STAGE',
    PTI_17_6 = 'PTI_17_6',
    BUS_STOP = 'BUS_STOP',
    PTI_17_255 = 'PTI_17_255',
    UNDEFINED_BOOKING_INFORMATION = 'UNDEFINED_BOOKING_INFORMATION',
  }
}
