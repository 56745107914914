/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LocalityRefStructure } from './LocalityRefStructure';
import type { OperatorFilterStructure } from './OperatorFilterStructure';
import type { PointOfInterestFilterStructure } from './PointOfInterestFilterStructure';
import type { PtModeFilterStructure } from './PtModeFilterStructure';

export type LocationParamStructure = {
  type?: Array<'STOP' | 'ADDRESS' | 'POI' | 'COORD' | 'LOCALITY'>;
  usage?: LocationParamStructure.usage;
  ptModes?: PtModeFilterStructure;
  operatorFilter?: OperatorFilterStructure;
  localityRef?: Array<LocalityRefStructure>;
  pointOfInterestFilter?: PointOfInterestFilterStructure;
  language?: string;
  numberOfResults?: number;
  continueAt?: number;
  includePtModes?: boolean;
};

export namespace LocationParamStructure {
  export enum usage {
    ORIGIN = 'ORIGIN',
    VIA = 'VIA',
    DESTINATION = 'DESTINATION',
  }
}
