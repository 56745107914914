/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type NotifyByEmailActionStructure = {
  actionStatus?: NotifyByEmailActionStructure.actionStatus;
  description?: NaturalLanguageStringStructure;
  actionData?: Array<ActionDataStructure>;
  beforeNotices?: BeforeNotices;
  clearNotice?: boolean;
  email?: string;
};

export namespace NotifyByEmailActionStructure {
  export enum actionStatus {
    OPEN = 'OPEN',
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED',
  }
}
