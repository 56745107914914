/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AffectedPathLinkStructure } from './AffectedPathLinkStructure';
import type { ConnectionLinkRefStructure } from './ConnectionLinkRefStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { LineRefStructure } from './LineRefStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { ZoneRefStructure } from './ZoneRefStructure';

export type AffectedConnectionLinkStructure = {
  affectedPathLink?: Array<AffectedPathLinkStructure>;
  allLines?: any;
  connectingStopPointName?: NaturalLanguageStringStructure;
  connectingStopPointRef?: StopPointRefStructure;
  connectingZoneRef?: ZoneRefStructure;
  connectionDirection?: AffectedConnectionLinkStructure.connectionDirection;
  connectionLinkRef?: Array<ConnectionLinkRefStructure>;
  connectionName?: NaturalLanguageStringStructure;
  extensions?: ExtensionsStructure;
  lineRef?: Array<LineRefStructure>;
};

export namespace AffectedConnectionLinkStructure {
  export enum connectionDirection {
    TO = 'TO',
    FROM = 'FROM',
    BOTH = 'BOTH',
  }
}
