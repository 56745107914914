/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JourneyType } from './JourneyType';

export type ReferencedJourneyType = {
  journey?: JourneyType;
  type: ReferencedJourneyType.type;
  origFromIdx?: number;
  origToIdx?: number;
  refFromIdx?: number;
  refToIdx?: number;
};

export namespace ReferencedJourneyType {
  export enum type {
    UNDEF = 'UNDEF',
    DEFAULT = 'DEFAULT',
    IST_ERSATZFAHRT = 'IST_ERSATZFAHRT',
    IST_VERSTAERKERFAHRT = 'IST_VERSTAERKERFAHRT',
    IST_FORTFUEHRUNG = 'IST_FORTFUEHRUNG',
    IST_TRENNUNG = 'IST_TRENNUNG',
    IST_FORTFUEHRUNG_VON_TRENNUNG = 'IST_FORTFUEHRUNG_VON_TRENNUNG',
    IST_ZUSAMMENFUEHRUNG = 'IST_ZUSAMMENFUEHRUNG',
    IST_FORTFUEHRUNG_DURCH_ZUSAMMENFUEHRUNG = 'IST_FORTFUEHRUNG_DURCH_ZUSAMMENFUEHRUNG',
    IST_ENTLASTUNG = 'IST_ENTLASTUNG',
    DEFAULT_R = 'DEFAULT_R',
    HAT_ERSATZFAHRT = 'HAT_ERSATZFAHRT',
    HAT_VERSTAERKERFAHRT = 'HAT_VERSTAERKERFAHRT',
    HAT_FORTFUEHRUNG = 'HAT_FORTFUEHRUNG',
    HAT_TRENNUNG = 'HAT_TRENNUNG',
    HAT_FORTFUEHRUNG_VON_TRENNUNG = 'HAT_FORTFUEHRUNG_VON_TRENNUNG',
    HAT_ZUSAMMENFUEHRUNG = 'HAT_ZUSAMMENFUEHRUNG',
    HAT_FORTFUEHRUNG_DURCH_ZUSAMMENFUEHRUNG = 'HAT_FORTFUEHRUNG_DURCH_ZUSAMMENFUEHRUNG',
    HAT_ENTLASTUNG = 'HAT_ENTLASTUNG',
  }
}
