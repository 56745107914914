import { Box, Button, css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { useStore } from '@/setup/global-state';

const PrintButtons = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${theme.spacing(1)};
    position: absolute;
    top: ${theme.spacing(6)};
    left: 50%;
    transform: translateX(-50%);

    @media print {
      display: none;
    }
  `,
);

const StyledButton = styled(Button)(
  () => css`
    text-align: center;
    white-space: nowrap;

    @media print {
      display: none;
    }
  `,
) as typeof Button;

export function PrintButtonGroup() {
  const routes = useStore((state) => state.routes);

  return (
    <PrintButtons>
      <StyledButton
        disabled={routes === undefined}
        variant="outlined"
        size="small"
        sx={{ mr: 0 }}
        onClick={() => window.print()}
      >
        Jetzt drucken
      </StyledButton>
      <StyledButton variant="outlined" size="small" sx={{ mr: 0 }} component={Link} to="#">
        Druckansicht beenden
      </StyledButton>
    </PrintButtons>
  );
}
