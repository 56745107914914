/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DirectionRefStructure } from './DirectionRefStructure';
import type { GeneralAttributeStructure } from './GeneralAttributeStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { JourneyRefStructure } from './JourneyRefStructure';
import type { LineRefStructure } from './LineRefStructure';
import type { ModeStructure } from './ModeStructure';
import type { OperatingDayRefStructure } from './OperatingDayRefStructure';
import type { OperatorRefStructure } from './OperatorRefStructure';
import type { ServiceViaPointStructure } from './ServiceViaPointStructure';
import type { SituationFullRefStructure } from './SituationFullRefStructure';
import type { StopPointRefStructure } from './StopPointRefStructure';
import type { VehicleRefStructure } from './VehicleRefStructure';

export type DatedJourneyStructure = {
  operatingDayRef: OperatingDayRefStructure;
  vehicleRef?: VehicleRefStructure;
  journeyRef: JourneyRefStructure;
  lineRef: LineRefStructure;
  directionRef: DirectionRefStructure;
  mode: ModeStructure;
  publishedLineName: InternationalTextStructure;
  operatorRef?: OperatorRefStructure;
  routeDescription?: InternationalTextStructure;
  via?: Array<ServiceViaPointStructure>;
  attribute?: Array<GeneralAttributeStructure>;
  originStopPointRef?: StopPointRefStructure;
  originText?: InternationalTextStructure;
  destinationStopPointRef?: StopPointRefStructure;
  destinationText: InternationalTextStructure;
  unplanned?: boolean;
  cancelled?: boolean;
  deviation?: boolean;
  occupancy?: DatedJourneyStructure.occupancy;
  situationFullRef?: Array<SituationFullRefStructure>;
};

export namespace DatedJourneyStructure {
  export enum occupancy {
    MANY_SEATS_AVAILABLE = 'MANY_SEATS_AVAILABLE',
    FEW_SEATS_AVAILABLE = 'FEW_SEATS_AVAILABLE',
    NO_SEATS_AVAILABLE = 'NO_SEATS_AVAILABLE',
    STANDING_AVAILABLE = 'STANDING_AVAILABLE',
    FULL = 'FULL',
  }
}
