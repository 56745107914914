import loadable from '@loadable/component';
import { Navigate, Route } from 'react-router-dom';

import { InformationDialog } from '@/components/InformationDialog';
import { PageLayout } from '@/components/PageLayout';
import { SentryRoutes } from '@/setup/sentry';

const HomePage = loadable(() => import('./pages/HomePage'), {
  resolveComponent: (components) => components.HomePage,
});
const ResultPage = loadable(() => import('./pages/ResultPage'), {
  resolveComponent: (components) => components.ResultPage,
});

const ContactPage = loadable(() => import('./pages/ContactPage'), {
  resolveComponent: (components) => components.ContactPage,
});
const DataPrivacyPage = loadable(() => import('./pages/DataPrivacyPage'), {
  resolveComponent: (components) => components.DataPrivacyPage,
});
const ImprintPage = loadable(() => import('./pages/ImprintPage'), {
  resolveComponent: (components) => components.ImprintPage,
});
const AccessibilityPage = loadable(() => import('./pages/AccessibilityPage'), {
  resolveComponent: (components) => components.AccessibilityPage,
});

export function App() {
  return (
    <>
      <SentryRoutes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="ihre-gesunde-route" element={<ResultPage />} />

          <Route path="kontakt" element={<ContactPage />} />
          <Route path="datenschutz" element={<DataPrivacyPage />} />
          <Route path="impressum" element={<ImprintPage />} />
          <Route path="barrierefreiheitserklaerung" element={<AccessibilityPage />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </SentryRoutes>
      <InformationDialog />
    </>
  );
}
