import { Stack, styled, Toolbar, Link as MuiLink, useMediaQuery, css, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { useStore } from '@/setup/global-state';

const links = [
  { label: 'Mehr zu Berechnungen und Kennwerten' },
  { label: 'Kontakt', to: '/kontakt' },
  { label: 'Impressum', to: '/impressum' },
  {
    label: 'Datenschutz',
    to: '/datenschutz',
  },

  { label: 'Barrierefreiheitserklärung', to: '/barrierefreiheitserklaerung' },
];

const StyledToolbar = styled(Toolbar)(
  ({ theme }) => css`
    background-color: ${theme.palette.text.primary};
    color: ${theme.palette.common.white};
    padding: ${theme.spacing(2)};
    display: flex;
    justify-content: center;

    ${theme.breakpoints.up('md')} {
      justify-content: flex-end;
      height: ${theme.spacing(10)};
    }

    @media print {
      display: none;
    }
  `,
) as typeof Toolbar;

const StyledLink = styled(MuiLink)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    text-decoration: none;
    font-size: 0.875rem;
    flex-grow: 0;
    display: inline-block;
    padding-top: ${theme.spacing(0.5)};
    padding-bottom: ${theme.spacing(0.5)};
    outline-color: ${theme.palette.common.white} !important;

    &:hover {
      text-decoration: underline;
    }
  `,
) as typeof MuiLink;

export function Footer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const setDialogOpen = useStore((state) => state.setDialogOpen);

  return (
    <StyledToolbar component="footer">
      <Stack direction={matches ? 'row' : 'column'} spacing={matches ? 4 : 1} alignItems="center">
        {links.map(({ label, to }) =>
          to !== undefined ? (
            <StyledLink component={Link} key={to} to={to}>
              {label}
            </StyledLink>
          ) : (
            <StyledLink key={label} component="button" onClick={() => setDialogOpen(true)}>
              {label}
            </StyledLink>
          ),
        )}
      </Stack>
    </StyledToolbar>
  );
}
