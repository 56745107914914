/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AccessPathStructure = {
  transition?: AccessPathStructure.transition;
  accessFeatureType?: AccessPathStructure.accessFeatureType;
  count?: number;
};

export namespace AccessPathStructure {
  export enum transition {
    UP = 'UP',
    DOWN = 'DOWN',
    LEVEL = 'LEVEL',
    UP_AND_DOWN = 'UP_AND_DOWN',
    DOWN_AND_UP = 'DOWN_AND_UP',
  }

  export enum accessFeatureType {
    LIFT = 'LIFT',
    STAIRS = 'STAIRS',
    SERIES_OF_STAIRS = 'SERIES_OF_STAIRS',
    ESCALATOR = 'ESCALATOR',
    RAMP = 'RAMP',
    FOOTPATH = 'FOOTPATH',
  }
}
