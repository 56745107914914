/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type NotifyByPagerActionStructure = {
  actionData?: Array<ActionDataStructure>;
  actionStatus?: NotifyByPagerActionStructure.actionStatus;
  beforeNotices?: BeforeNotices;
  clearNotice?: boolean;
  description?: NaturalLanguageStringStructure;
  pager?: string;
  pagerGroupRef?: string;
};

export namespace NotifyByPagerActionStructure {
  export enum actionStatus {
    OPEN = 'OPEN',
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED',
  }
}
