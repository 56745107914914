/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AffectedStopType } from './AffectedStopType';
import type { JourneyType } from './JourneyType';
import type { MessageCategoryType } from './MessageCategoryType';
import type { MessageChannelType } from './MessageChannelType';
import type { MessageEdgeType } from './MessageEdgeType';
import type { MessageEventType } from './MessageEventType';
import type { MessageRegionType } from './MessageRegionType';
import type { MessageTextType } from './MessageTextType';
import type { Note } from './Note';
import type { ProductType } from './ProductType';
import type { ServiceDays } from './ServiceDays';
import type { StopType } from './StopType';
import type { TagsType } from './TagsType';

export type Message = {
  dailyDuration?: string;
  stime?: string;
  sdate?: string;
  etime?: string;
  edate?: string;
  readonly affectedProduct?: Array<ProductType>;
  readonly affectedJourney?: Array<JourneyType>;
  readonly edge?: Array<MessageEdgeType>;
  readonly region?: Array<MessageRegionType>;
  readonly event?: Array<MessageEventType>;
  affectedStops?: AffectedStopType;
  validFromStop?: StopType;
  validToStop?: StopType;
  tags?: TagsType;
  readonly channel?: Array<MessageChannelType>;
  readonly messageCategory?: Array<MessageCategoryType>;
  readonly messageText?: Array<MessageTextType>;
  validityDays?: ServiceDays;
  readonly Note?: Array<Note>;
  readonly message?: Array<Message>;
  id: string;
  externalId?: string;
  act: boolean;
  head: string;
  lead?: string;
  text: string;
  customText?: string;
  tckr?: string;
  company?: string;
  category?: string;
  priority: number;
  products?: number;
  icon?: string;
  routeIdxFrom?: number;
  routeIdxTo?: number;
  sTime?: string;
  sDate?: string;
  eTime?: string;
  eDate?: string;
  altStart?: string;
  altEnd?: string;
  modTime?: string;
  modDate?: string;
  dailyStartingAt?: string;
  baseType?: Message.baseType;
};

export namespace Message {
  export enum baseType {
    UNDEF = 'UNDEF',
    GLOBAL = 'GLOBAL',
    INFRASTRUCTURE = 'INFRASTRUCTURE',
  }
}
