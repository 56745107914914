/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryRefStructure } from './CountryRefStructure';
import type { EntryQualifierStructure } from './EntryQualifierStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { ParticipantRefStructure } from './ParticipantRefStructure';
import type { SituationVersion } from './SituationVersion';

export type RelatedSituationStructure = {
  creationTime: string;
  countryRef?: CountryRefStructure;
  participantRef?: ParticipantRefStructure;
  situationNumber: EntryQualifierStructure;
  updateCountryRef?: CountryRefStructure;
  updateParticipantRef?: ParticipantRefStructure;
  version?: SituationVersion;
  externalReference?: string;
  relatedAs?: RelatedSituationStructure.relatedAs;
  extensions?: ExtensionsStructure;
};

export namespace RelatedSituationStructure {
  export enum relatedAs {
    CAUSE = 'CAUSE',
    EFFECT = 'EFFECT',
    UPDATE = 'UPDATE',
    SUPERCEDES = 'SUPERCEDES',
    SUPERCEDED_BY = 'SUPERCEDED_BY',
    ASSOCIATED = 'ASSOCIATED',
  }
}
