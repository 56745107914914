/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GisEdgeType } from './GisEdgeType';
import type { Messages } from './Messages';
import type { Notes } from './Notes';
import type { TrafficMessageType } from './TrafficMessageType';

export type GisRouteSegment = {
  durS?: string;
  rnum?: string;
  rtype?: GisRouteSegment.rtype;
  Notes?: Notes;
  readonly Edge?: Array<GisEdgeType>;
  readonly TrafficMessage?: Array<TrafficMessageType>;
  Messages?: Messages;
  name?: string;
  rType?: GisRouteSegment.rType;
  rNum?: string;
  man?: GisRouteSegment.man;
  manTx?: string;
  manTargetName?: string;
  dirTx?: string;
  ori?: GisRouteSegment.ori;
  polyS?: number;
  polyE?: number;
  dist?: number;
};

export namespace GisRouteSegment {
  export enum rtype {
    U = 'U',
    M = 'M',
    H = 'H',
    T = 'T',
    T_4_L = 'T_4_L',
    T_2_L = 'T_2_L',
    TR = 'TR',
    NT = 'NT',
    CT = 'CT',
    R = 'R',
    B = 'B',
    CW = 'CW',
    C = 'C',
    W = 'W',
    F = 'F',
  }

  export enum rType {
    U = 'U',
    M = 'M',
    H = 'H',
    T = 'T',
    T_4_L = 'T_4_L',
    T_2_L = 'T_2_L',
    TR = 'TR',
    NT = 'NT',
    CT = 'CT',
    R = 'R',
    B = 'B',
    CW = 'CW',
    C = 'C',
    W = 'W',
    F = 'F',
  }

  export enum man {
    NO = 'NO',
    FR = 'FR',
    TO = 'TO',
    ON = 'ON',
    LE = 'LE',
    RI = 'RI',
    KL = 'KL',
    KR = 'KR',
    HL = 'HL',
    HR = 'HR',
    KHL = 'KHL',
    KHR = 'KHR',
    SL = 'SL',
    SR = 'SR',
    KSL = 'KSL',
    KSR = 'KSR',
    ST = 'ST',
    UT = 'UT',
    EN = 'EN',
    LV = 'LV',
    ER = 'ER',
    SIR = 'SIR',
    LR = 'LR',
    EF = 'EF',
    LF = 'LF',
    CH = 'CH',
    CIF = 'CIF',
    COF = 'COF',
    EL = 'EL',
    ELD = 'ELD',
    ELU = 'ELU',
    ES = 'ES',
    ESD = 'ESD',
    ESU = 'ESU',
    STA = 'STA',
    STD = 'STD',
    STU = 'STU',
  }

  export enum ori {
    U = 'U',
    N = 'N',
    S = 'S',
    E = 'E',
    W = 'W',
    NE = 'NE',
    SE = 'SE',
    NW = 'NW',
    SW = 'SW',
  }
}
