/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JourneyPathItemType = {
  progressInTime?: number;
  progressInPercent?: number;
  progressAbs?: number;
  fromLocationId?: string;
  toLocationId?: string;
  dirGeo?: number;
  state?: JourneyPathItemType.state;
};

export namespace JourneyPathItemType {
  export enum state {
    U = 'U',
    B = 'B',
    O = 'O',
    A = 'A',
  }
}
