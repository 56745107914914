/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Coordinate } from './Coordinate';
import type { Directions } from './Directions';
import type { Messages } from './Messages';
import type { Names } from './Names';
import type { Notes } from './Notes';
import type { ParallelJourneyRefType } from './ParallelJourneyRefType';
import type { Polyline } from './Polyline';
import type { PolylineGroup } from './PolylineGroup';
import type { ProductType } from './ProductType';
import type { ReferencedJourneyType } from './ReferencedJourneyType';
import type { ServiceDays } from './ServiceDays';
import type { Stops } from './Stops';
import type { TechnicalMessages } from './TechnicalMessages';
import type { Warnings } from './Warnings';

export type JourneyDetail = {
  TechnicalMessages?: TechnicalMessages;
  Warnings?: Warnings;
  serverVersion?: string;
  dialectVersion?: string;
  version?: string;
  planRtTs?: string;
  errorCode?: string;
  errorText?: string;
  requestId?: string;
  Stops: Stops;
  Names?: Names;
  readonly Product?: Array<ProductType>;
  Directions?: Directions;
  Notes?: Notes;
  Messages?: Messages;
  JourneyStatus?: JourneyDetail.JourneyStatus;
  Polyline?: Polyline;
  PolylineGroup?: PolylineGroup;
  readonly ServiceDays?: Array<ServiceDays>;
  readonly referencedJourney?: Array<ReferencedJourneyType>;
  lastPos?: Coordinate;
  lastPosReported?: string;
  lastPassRouteIdx?: number;
  lastPassStopRef?: number;
  rtLastPassRouteIdx?: number;
  rtLastPassStopRef?: number;
  readonly ParallelJourneyRef?: Array<ParallelJourneyRefType>;
  cancelled?: boolean;
  partCancelled?: boolean;
  reachable?: boolean;
  redirected?: boolean;
  uncertainDelay?: boolean;
  dayOfOperation: string;
  ref: string;
};

export namespace JourneyDetail {
  export enum JourneyStatus {
    P = 'P',
    R = 'R',
    A = 'A',
    S = 'S',
  }
}
