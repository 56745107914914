/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DataNameSpacesStructure } from './DataNameSpacesStructure';

export type ServiceRequestContextStructure = {
  checkStatusAddress?: string;
  subscribeAddress?: string;
  manageSubscriptionAddress?: string;
  getDataAddress?: string;
  statusResponseAddress?: string;
  subscriberAddress?: string;
  notifyAddress?: string;
  consumerAddress?: string;
  dataNameSpaces?: DataNameSpacesStructure;
  language?: string;
  wgsDecimalDegrees?: string;
  gmlCoordinateFormat?: string;
  dataHorizon?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  requestTimeout?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  deliveryMethod?: ServiceRequestContextStructure.deliveryMethod;
  multipartDespatch?: boolean;
  confirmDelivery?: boolean;
  maximimumNumberOfSubscriptions?: number;
  allowedPredictors?: ServiceRequestContextStructure.allowedPredictors;
  predictionFunction?: string;
};

export namespace ServiceRequestContextStructure {
  export enum deliveryMethod {
    DIRECT = 'DIRECT',
    FETCHED = 'FETCHED',
  }

  export enum allowedPredictors {
    AVMS_ONLY = 'AVMS_ONLY',
    ANYONE = 'ANYONE',
  }
}
