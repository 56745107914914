/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingInfoStructure } from './BookingInfoStructure';
import type { FaresAuthorityRefStructure } from './FaresAuthorityRefStructure';
import type { FareZoneListInAreaStructure } from './FareZoneListInAreaStructure';
import type { InternationalTextStructure } from './InternationalTextStructure';
import type { TravellerCardRefStructure } from './TravellerCardRefStructure';
import type { WebLinkStructure } from './WebLinkStructure';

export type TicketStructure = {
  ticketId: string;
  ticketName: string;
  faresAuthorityRef: FaresAuthorityRefStructure;
  faresAuthorityText: string;
  price?: number;
  netPrice?: number;
  currency?: string;
  vatRate?: TicketStructure.vatRate;
  travelClass?: TicketStructure.travelClass;
  requiredCard?: Array<TravellerCardRefStructure>;
  validFor?: Array<'ADULT' | 'CHILD' | 'SENIOR' | 'YOUTH' | 'DISABLED'>;
  validityDuration?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  validityDurationText?: InternationalTextStructure;
  validityFareZones?: Array<FareZoneListInAreaStructure>;
  validityAreaText?: InternationalTextStructure;
  infoUrl?: Array<WebLinkStructure>;
  saleUrl?: Array<WebLinkStructure>;
  bookingInfo?: Array<BookingInfoStructure>;
  extension?: any;
};

export namespace TicketStructure {
  export enum vatRate {
    NO = 'NO',
    FULL = 'FULL',
    HALF = 'HALF',
    MIXED = 'MIXED',
    UNKNOWN = 'UNKNOWN',
  }

  export enum travelClass {
    ALL = 'ALL',
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    BUSINESS = 'BUSINESS',
    ECONOMY = 'ECONOMY',
  }
}
