/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FareZoneRefListStructure } from './FareZoneRefListStructure';
import type { TicketRefStructure } from './TicketRefStructure';
import type { TravellerCardRefStructure } from './TravellerCardRefStructure';

export type FaresPassengerStructure = {
  age?: number;
  ownedTicket?: Array<TicketRefStructure>;
  passengerCategory?: FaresPassengerStructure.passengerCategory;
  travellerCard?: Array<TravellerCardRefStructure>;
  zonesAlreadyPaid?: FareZoneRefListStructure;
};

export namespace FaresPassengerStructure {
  export enum passengerCategory {
    ADULT = 'ADULT',
    CHILD = 'CHILD',
    SENIOR = 'SENIOR',
    YOUTH = 'YOUTH',
    DISABLED = 'DISABLED',
  }
}
