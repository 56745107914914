import { css } from '@emotion/react';
import { lighten } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';

import '@fontsource/source-sans-pro/latin-400.css';
import '@fontsource/source-sans-pro/latin-700.css';

let themeBuilder = createTheme(
  {
    palette: {
      primary: {
        main: '#3a3a3a',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#d9e3e8',
        light: '#eff4f7',
      },
      text: {
        primary: '#3a3a3a',
        secondary: '#687878',
      },
    },
    typography: {
      fontSize: 16,
      fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),

      allVariants: {
        fontSize: '1rem',
      },

      h1: {
        fontWeight: 'bold',
        fontSize: '2rem',
      },
      h2: {
        fontWeight: 'bold',
      },
    },
    shape: {
      borderRadius: 0,
    },
    shadows: [
      'none',
      '0px 3px 12px #00000029',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
  },
  deDE,
);

themeBuilder = createTheme(themeBuilder, {
  components: {
    MuiCssBaseline: {
      styleOverrides: () => css`
        body *:focus-visible {
          outline: 2px solid ${themeBuilder.palette.primary.main};
        }

        @media only print {
          .maplibregl-ctrl-attrib-button {
            display: none;
          }
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: css`
          border-radius: ${themeBuilder.spacing(2.5)};
          padding-left: ${themeBuilder.spacing(2.5)};
          padding-right: ${themeBuilder.spacing(2.5)};
          text-transform: initial;

          &.Mui-disabled {
            color: ${themeBuilder.palette.text.disabled};
          }

          &:hover {
            background-color: ${lighten(themeBuilder.palette.primary.main, 0.5)};
            color: ${themeBuilder.palette.common.white};
          }

          &:focus-visible {
            border: 2px solid ${themeBuilder.palette.common.white};
          }
        `,
        text: css`
          font-weight: bold;
        `,
        sizeLarge: css`
          height: ${themeBuilder.spacing(7)};
          border-radius: ${themeBuilder.spacing(3.5)};
          padding-left: ${themeBuilder.spacing(3.5)};
          padding-right: ${themeBuilder.spacing(3.5)};
          font-weight: bold;
          line-height: 1.2;
        `,
        contained: css`
          font-weight: bold;
        `,
        outlined: css`
          border: 2px solid ${themeBuilder.palette.primary.main};

          &:hover {
            border: 2px solid ${themeBuilder.palette.primary.main};
          }
        `,
        sizeSmall: css`
          font-weight: bold;
          padding-left: ${themeBuilder.spacing(1)};
          padding-right: ${themeBuilder.spacing(1)};
        `,
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: css`
          &:before {
            border-bottom: none;
          }

          &:hover {
            background-color: #f2f2f2;
          }
        `,
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: css`
          border: 2px solid ${themeBuilder.palette.primary.main};
          border-radius: ${themeBuilder.spacing(3.25)};
          font-weight: bold;
          text-transform: initial;
          padding: ${themeBuilder.spacing(0)} ${themeBuilder.spacing(0.75)};
          color: ${themeBuilder.palette.text.primary};

          &:hover {
            color: ${themeBuilder.palette.common.white};
            background-color: ${lighten(themeBuilder.palette.primary.main, 0.5)};
          }

          &:focus-visible {
            border: 2px solid ${themeBuilder.palette.common.white};
          }

          &.Mui-selected,
          &.Mui-selected:hover {
            color: ${themeBuilder.palette.common.white};
            background-color: ${themeBuilder.palette.primary.main};
          }
        `,
      },
    },

    MuiInput: {
      styleOverrides: {
        root: css`
          &::before {
            border-bottom: none;
          }
        `,
        input: css`
          padding-bottom: ${themeBuilder.spacing(1.5)} !important;
        `,
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: css`
          transform: translate(0, 10px) scale(1);
        `,
        shrink: css`
          transform: translate(0, -1.5px) scale(0.75);
        `,
      },
    },

    MuiMenu: {
      styleOverrides: {
        list: css`
          background-color: ${themeBuilder.palette.secondary.main};
          border: 2px solid ${themeBuilder.palette.common.white};
        `,
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: css`
          border: none;
        `,
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: css`
          border: 2px ${themeBuilder.palette.common.white} solid;
        `,
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: css`
          margin-bottom: 0;
        `,
        thumb: css`
          &:focus,
          &:hover,
          &.Mui-active {
            box-shadow:
              0 3px 1px rgba(0, 0, 0, 0.1),
              0 4px 8px rgba(0, 0, 0, 0.3),
              0 0 0 1px rgba(0, 0, 0, 0.02);
          }
        `,
        rail: css`
          background-color: ${themeBuilder.palette.common.white};
        `,
      },
    },

    MuiLink: {
      styleOverrides: {
        root: css`
          font-family: 'Source Sans Pro', sans-serif;

          &.Mui-focusVisible {
            outline: 2px solid ${themeBuilder.palette.primary.main};
          }
        `,
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: css`
          margin: ${themeBuilder.spacing(2)};

          ${themeBuilder.breakpoints.up('md')} {
            margin: ${themeBuilder.spacing(4)};
          }
        `,
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: css`
          padding: ${themeBuilder.spacing(2)};

          ${themeBuilder.breakpoints.up('sm')} {
            padding: ${themeBuilder.spacing(6)};
          }

          ${themeBuilder.breakpoints.up('md')} {
            padding: ${themeBuilder.spacing(12)};
          }
        `,
      },
    },

    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
} as ThemeOptions);

themeBuilder = responsiveFontSizes(themeBuilder);

export const theme = themeBuilder;
