/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { StopSequenceStructure } from './StopSequenceStructure';

export type VehicleActivityStructure = {
  currentStopIndex?: number;
  journeyMode?: VehicleActivityStructure.journeyMode;
  locationState?: VehicleActivityStructure.locationState;
  nextExitSide?: VehicleActivityStructure.nextExitSide;
  routeDeviation?: VehicleActivityStructure.routeDeviation;
  stopSequence?: Array<StopSequenceStructure>;
  timetableDelay?: number;
};

export namespace VehicleActivityStructure {
  export enum journeyMode {
    NO_TRIP = 'NO_TRIP',
    ADDITIONAL_TRIP = 'ADDITIONAL_TRIP',
    SERVICE_TRIP = 'SERVICE_TRIP',
  }

  export enum locationState {
    AFTER_STOP = 'AFTER_STOP',
    AT_STOP = 'AT_STOP',
    BEFORE_STOP = 'BEFORE_STOP',
    BETWEEN_STOP = 'BETWEEN_STOP',
  }

  export enum nextExitSide {
    BOTH = 'BOTH',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    UNKNOWN = 'UNKNOWN',
  }

  export enum routeDeviation {
    ONROUTE = 'ONROUTE',
    OFFROUTE = 'OFFROUTE',
    UNKNOWN = 'UNKNOWN',
  }
}
