/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { StopType } from './StopType';

export type ParallelJourneyRefType = {
  fromLocation?: StopType;
  toLocation?: StopType;
  journeyDetailRef?: string;
  type?: ParallelJourneyRefType.type;
};

export namespace ParallelJourneyRefType {
  export enum type {
    UNDEF = 'UNDEF',
    UNION = 'UNION',
    THROUGHCOACH = 'THROUGHCOACH',
    TIETHROUGH = 'TIETHROUGH',
  }
}
