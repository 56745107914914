/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Advice } from './Advice';
import type { Cause } from './Cause';
import type { ComplexComment } from './ComplexComment';
import type { DateTime } from './DateTime';
import type { ExtensionType } from './ExtensionType';
import type { GroupOfLocations } from './GroupOfLocations';
import type { Impact } from './Impact';
import type { Management } from './Management';
import type { SourceInformation } from './SourceInformation';
import type { Validity } from './Validity';

export type SituationRecord = {
  situationRecordCreationReference?: string;
  situationRecordCreationTime: DateTime;
  situationRecordObservationTime?: DateTime;
  situationRecordVersion: number;
  situationRecordVersionTime: DateTime;
  situationRecordFirstSupplierVersionTime: DateTime;
  informationUsageOverride?: SituationRecord.informationUsageOverride;
  probabilityOfOccurrence: SituationRecord.probabilityOfOccurrence;
  sourceInformation?: SourceInformation;
  validity: Validity;
  impact?: Impact;
  cause?: Cause;
  advice?: Array<Advice>;
  generalPublicComment?: Array<ComplexComment>;
  nonGeneralPublicComment?: Array<ComplexComment>;
  groupOfLocations: GroupOfLocations;
  management?: Management;
  situationRecordExtension?: ExtensionType;
  id?: string;
};

export namespace SituationRecord {
  export enum informationUsageOverride {
    BROADCAST = 'BROADCAST',
    INTERNAL = 'INTERNAL',
    INTERNET = 'INTERNET',
    VARIABLE_MESSAGE_SIGN = 'VARIABLE_MESSAGE_SIGN',
  }

  export enum probabilityOfOccurrence {
    CERTAIN = 'CERTAIN',
    PROBABLE = 'PROBABLE',
    RISK_OF = 'RISK_OF',
    IMPROBABLE = 'IMPROBABLE',
  }
}
