import dayjs from 'dayjs';
import 'dayjs/locale/de';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjsBusinessTime from 'dayjs-business-time';

dayjs.locale('de');
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(dayjsBusinessTime);
