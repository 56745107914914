/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';

export type IndividualTransportOptionsStructure = {
  maxDistance?: number;
  maxDuration?: Duration;
  minDistance?: number;
  minDuration?: Duration;
  mode?: IndividualTransportOptionsStructure.mode;
  speed?: number;
};

export namespace IndividualTransportOptionsStructure {
  export enum mode {
    WALK = 'WALK',
    CYCLE = 'CYCLE',
    TAXI = 'TAXI',
    SELF_DRIVE_CAR = 'SELF_DRIVE_CAR',
    OTHERS_DRIVE_CAR = 'OTHERS_DRIVE_CAR',
    MOTORCYCLE = 'MOTORCYCLE',
    TRUCK = 'TRUCK',
  }
}
