/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ModeParam = {
  mode: ModeParam.mode;
  minDistance?: number;
  maxDistance?: number;
};

export namespace ModeParam {
  export enum mode {
    WALK = 'WALK',
    BIKE = 'BIKE',
    PT = 'PT',
    CAR = 'CAR',
  }
}
