/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FaresAuthorityRefStructure } from './FaresAuthorityRefStructure';
import type { FaresPassengerStructure } from './FaresPassengerStructure';

export type FaresParamStructure = {
  fareAuthorityFilter?: Array<FaresAuthorityRefStructure>;
  passengerCategory?: Array<'ADULT' | 'CHILD' | 'SENIOR' | 'YOUTH' | 'DISABLED'>;
  travelClass?: FaresParamStructure.travelClass;
  traveller?: Array<FaresPassengerStructure>;
};

export namespace FaresParamStructure {
  export enum travelClass {
    ALL = 'ALL',
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD',
    BUSINESS = 'BUSINESS',
    ECONOMY = 'ECONOMY',
  }
}
