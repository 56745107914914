/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LineDirectionFilterStructure } from './LineDirectionFilterStructure';
import type { OperatorFilterStructure } from './OperatorFilterStructure';
import type { PtModeFilterStructure } from './PtModeFilterStructure';

export type StopEventParamStructure = {
  ptModeFilter?: PtModeFilterStructure;
  lineFilter?: LineDirectionFilterStructure;
  operatorFilter?: OperatorFilterStructure;
  numberOfResults?: number;
  timeWindow?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  stopEventType?: StopEventParamStructure.stopEventType;
  includePreviousCalls?: boolean;
  includeOnwardCalls?: boolean;
  includeOperatingDays?: boolean;
  includeRealtimeData?: boolean;
};

export namespace StopEventParamStructure {
  export enum stopEventType {
    DEPARTURE = 'DEPARTURE',
    ARRIVAL = 'ARRIVAL',
    BOTH = 'BOTH',
  }
}
