import { Button, styled, css } from '@mui/material';

export const ButtonLink = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    padding: 0;
    min-width: 0;
    vertical-align: baseline;
    text-transform: none;
    text-decoration: underline;
    font-size: inherit;

    &.MuiButton-root:hover {
      background-color: transparent;
      color: ${theme.palette.text.primary};
    }
  `,
) as typeof Button;
