/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { MonitoringValidityConditionStructure } from './MonitoringValidityConditionStructure';

export type MonitoringInformationStructure = {
  extensions?: ExtensionsStructure;
  monitoringInterval?: Duration;
  monitoringPeriod?: MonitoringValidityConditionStructure;
  monitoringType?: MonitoringInformationStructure.monitoringType;
};

export namespace MonitoringInformationStructure {
  export enum monitoringType {
    UNKNOWN = 'UNKNOWN',
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
  }
}
