/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConnectionReliabilityType = {
  original?: ConnectionReliabilityType.original;
  alternative?: ConnectionReliabilityType.alternative;
};

export namespace ConnectionReliabilityType {
  export enum original {
    GUARANTEED = 'GUARANTEED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    ABORTIVE = 'ABORTIVE',
    UNDEF = 'UNDEF',
  }

  export enum alternative {
    GUARANTEED = 'GUARANTEED',
    HIGH = 'HIGH',
    LOW = 'LOW',
    ABORTIVE = 'ABORTIVE',
    UNDEF = 'UNDEF',
  }
}
