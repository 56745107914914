/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';

export type StopType = {
  Notes?: Notes;
  altId?: Array<string>;
  mainMastAltId?: Array<string>;
  Occupancy?: Array<OccupancyType>;
  name: string;
  id: string;
  extId: string;
  routeIdx?: number;
  lon?: number;
  lat?: number;
  arrPrognosisType?: StopType.arrPrognosisType;
  depPrognosisType?: StopType.depPrognosisType;
  depTime?: string;
  depDate?: string;
  depTz?: number;
  scheduledDepTimeChanged?: boolean;
  arrTime?: string;
  arrDate?: string;
  arrTz?: number;
  scheduledArrTimeChanged?: boolean;
  passingTime?: string;
  passingDate?: string;
  passingTz?: number;
  arrTrack?: string;
  arrTrackHidden?: boolean;
  depTrack?: string;
  depTrackHidden?: boolean;
  rtDepTime?: string;
  rtDepDate?: string;
  rtDepTz?: number;
  rtArrTime?: string;
  rtArrDate?: string;
  rtArrTz?: number;
  rtArrTrack?: string;
  rtArrTrackHidden?: boolean;
  rtDepTrack?: string;
  rtDepTrackHidden?: boolean;
  rtPassingTime?: string;
  rtPassingDate?: string;
  rtPassingTz?: number;
  cancelled?: boolean;
  cancelledDeparture?: boolean;
  cancelledArrival?: boolean;
  hasMainMast?: boolean;
  mainMastId?: string;
  mainMastExtId?: string;
  alighting?: boolean;
  boarding?: boolean;
  rtAlighting?: boolean;
  rtBoarding?: boolean;
  additional?: boolean;
  isBorderStop?: boolean;
  isTurningPoint?: boolean;
  arrHide?: boolean;
  depHide?: boolean;
  depDir?: string;
  entry?: boolean;
  rtCnclDataSourceType?: StopType.rtCnclDataSourceType;
  arrUncertainDelay?: boolean;
  depUncertainDelay?: boolean;
};

export namespace StopType {
  export enum arrPrognosisType {
    PROGNOSED = 'PROGNOSED',
    MANUAL = 'MANUAL',
    REPORTED = 'REPORTED',
    CORRECTED = 'CORRECTED',
    CALCULATED = 'CALCULATED',
  }

  export enum depPrognosisType {
    PROGNOSED = 'PROGNOSED',
    MANUAL = 'MANUAL',
    REPORTED = 'REPORTED',
    CORRECTED = 'CORRECTED',
    CALCULATED = 'CALCULATED',
  }

  export enum rtCnclDataSourceType {
    DEFAULT = 'DEFAULT',
    VDV = 'VDV',
    HIM = 'HIM',
    HRC = 'HRC',
    SIRI = 'SIRI',
    UIC = 'UIC',
    HRX = 'HRX',
    GTFS = 'GTFS',
    FIS = 'FIS',
    DDS = 'DDS',
    PAISA = 'PAISA',
    FE = 'FE',
    BLACKLIST = 'BLACKLIST',
  }
}
