/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AffectsScopeStructure } from './AffectsScopeStructure';
import type { BlockingStructure } from './BlockingStructure';
import type { BoardingStructure } from './BoardingStructure';
import type { CasualtiesStructure } from './CasualtiesStructure';
import type { DelaysStructure } from './DelaysStructure';
import type { EasementsStructure } from './EasementsStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { HalfOpenTimestampRangeStructure } from './HalfOpenTimestampRangeStructure';
import type { PtAdviceStructure } from './PtAdviceStructure';
import type { Suitabilities } from './Suitabilities';

export type PtConsequenceStructure = {
  period?: HalfOpenTimestampRangeStructure;
  condition?: PtConsequenceStructure.condition;
  severity: PtConsequenceStructure.severity;
  affects?: AffectsScopeStructure;
  suitabilities?: Suitabilities;
  advice?: PtAdviceStructure;
  blocking?: BlockingStructure;
  boarding?: BoardingStructure;
  delays?: DelaysStructure;
  casualties?: CasualtiesStructure;
  easements?: Array<EasementsStructure>;
  extensions?: ExtensionsStructure;
};

export namespace PtConsequenceStructure {
  export enum condition {
    PTI_13_0 = 'PTI_13_0',
    UNKNOWN = 'UNKNOWN',
    PTI_13_1 = 'PTI_13_1',
    ALTERED = 'ALTERED',
    PTI_13_2 = 'PTI_13_2',
    CANCELLED = 'CANCELLED',
    PTI_13_3 = 'PTI_13_3',
    DELAYED = 'DELAYED',
    PTI_13_4 = 'PTI_13_4',
    DIVERTED = 'DIVERTED',
    PTI_13_5 = 'PTI_13_5',
    NO_SERVICE = 'NO_SERVICE',
    PTI_13_6 = 'PTI_13_6',
    DISRUPTED = 'DISRUPTED',
    PTI_13_7 = 'PTI_13_7',
    ADDITIONAL_SERVICE = 'ADDITIONAL_SERVICE',
    PTI_13_8 = 'PTI_13_8',
    SPECIAL_SERVICE = 'SPECIAL_SERVICE',
    PTI_13_9 = 'PTI_13_9',
    ON_TIME = 'ON_TIME',
    PTI_13_10 = 'PTI_13_10',
    NORMAL_SERVICE = 'NORMAL_SERVICE',
    PTI_13_11 = 'PTI_13_11',
    INTERMITTENT_SERVICE = 'INTERMITTENT_SERVICE',
    PTI_13_12 = 'PTI_13_12',
    SHORT_FORMED_SERVICE = 'SHORT_FORMED_SERVICE',
    PTI_13_13 = 'PTI_13_13',
    FULL_LENGTH_SERVICE = 'FULL_LENGTH_SERVICE',
    PTI_13_14 = 'PTI_13_14',
    EXTENDED_SERVICE = 'EXTENDED_SERVICE',
    PTI_13_15 = 'PTI_13_15',
    SPLITTING_TRAIN = 'SPLITTING_TRAIN',
    PTI_13_16 = 'PTI_13_16',
    REPLACEMENT_TRANSPORT = 'REPLACEMENT_TRANSPORT',
    PTI_13_17 = 'PTI_13_17',
    ARRIVES_EARLY = 'ARRIVES_EARLY',
    PTI_13_18 = 'PTI_13_18',
    SHUTTLE_SERVICE = 'SHUTTLE_SERVICE',
    PTI_13_19 = 'PTI_13_19',
    REPLACEMENT_SERVICE = 'REPLACEMENT_SERVICE',
    PTI_13_255 = 'PTI_13_255',
    UNDEFINED_SERVICE_INFORMATION = 'UNDEFINED_SERVICE_INFORMATION',
  }

  export enum severity {
    PTI_26_0 = 'PTI_26_0',
    UNKNOWN = 'UNKNOWN',
    PTI_26_1 = 'PTI_26_1',
    VERY_SLIGHT = 'VERY_SLIGHT',
    PTI_26_2 = 'PTI_26_2',
    SLIGHT = 'SLIGHT',
    PTI_26_3 = 'PTI_26_3',
    NORMAL = 'NORMAL',
    PTI_26_4 = 'PTI_26_4',
    SEVERE = 'SEVERE',
    PTI_26_5 = 'PTI_26_5',
    VERY_SEVERE = 'VERY_SEVERE',
    PTI_26_6 = 'PTI_26_6',
    NO_IMPACT = 'NO_IMPACT',
    PTI_26_255 = 'PTI_26_255',
    UNDEFINED = 'UNDEFINED',
  }
}
