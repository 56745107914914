/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionType } from './ExtensionType';

export type ImpactDetails = {
  capacityRemaining?: number;
  impactDetailsExtension?: ExtensionType;
  numberOfLanesRestricted?: number;
  numberOfOperationalLanes?: number;
  originalNumberOfLanes?: number;
  trafficRestrictionType?: ImpactDetails.trafficRestrictionType;
};

export namespace ImpactDetails {
  export enum trafficRestrictionType {
    CARRIAGEWAY_BLOCKED = 'CARRIAGEWAY_BLOCKED',
    CARRIAGEWAY_PARTIALLY_OBSTRUCTED = 'CARRIAGEWAY_PARTIALLY_OBSTRUCTED',
    LANE_DEVIATED = 'LANE_DEVIATED',
    LANES_BLOCKED = 'LANES_BLOCKED',
    LANES_PARTIALLY_OBSTRUCTED = 'LANES_PARTIALLY_OBSTRUCTED',
    ROAD_BLOCKED = 'ROAD_BLOCKED',
    ROAD_PARTIALLY_OBSTRUCTED = 'ROAD_PARTIALLY_OBSTRUCTED',
  }
}
