/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionType } from './ExtensionType';
import type { OverallPeriod } from './OverallPeriod';

export type Validity = {
  validityStatus: Validity.validityStatus;
  validityTimeSpecification: OverallPeriod;
  validityExtension?: ExtensionType;
};

export namespace Validity {
  export enum validityStatus {
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',
    DEFINED_BY_VALIDITY_TIME_SPEC = 'DEFINED_BY_VALIDITY_TIME_SPEC',
  }
}
