/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionsStructure } from './ExtensionsStructure';
import type { MonitoringValidityConditionStructure } from './MonitoringValidityConditionStructure';

export type MonitoringInformationStructure = {
  monitoringInterval?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  monitoringType?: MonitoringInformationStructure.monitoringType;
  monitoringPeriod?: MonitoringValidityConditionStructure;
  extensions?: ExtensionsStructure;
};

export namespace MonitoringInformationStructure {
  export enum monitoringType {
    UNKNOWN = 'UNKNOWN',
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
  }
}
