/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Duration } from './Duration';

export type DelaysStructure = {
  delay?: Duration;
  delayBand?: DelaysStructure.delayBand;
  delayType?: DelaysStructure.delayType;
};

export namespace DelaysStructure {
  export enum delayBand {
    DELAY_BETWEEN_ONE_HOUR_AND_THREE_HOURS = 'DELAY_BETWEEN_ONE_HOUR_AND_THREE_HOURS',
    DELAY_BETWEEN_THIRTY_MINUTES_AND_ONE_HOUR = 'DELAY_BETWEEN_THIRTY_MINUTES_AND_ONE_HOUR',
    DELAY_BETWEEN_THREE_HOURS_AND_SIX_HOURS = 'DELAY_BETWEEN_THREE_HOURS_AND_SIX_HOURS',
    DELAY_LESS_THAN_THIRTY_MINUTES = 'DELAY_LESS_THAN_THIRTY_MINUTES',
    DELAY_LONGER_THAN_SIX_HOURS = 'DELAY_LONGER_THAN_SIX_HOURS',
    NEGLIGIBLE = 'NEGLIGIBLE',
  }

  export enum delayType {
    DELAYS = 'DELAYS',
    DELAYS_OF_UNCERTAIN_DURATION = 'DELAYS_OF_UNCERTAIN_DURATION',
    LONG_DELAYS = 'LONG_DELAYS',
    VERY_LONG_DELAYS = 'VERY_LONG_DELAYS',
  }
}
