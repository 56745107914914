/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IndividualTransportOptionsStructure = {
  mode: IndividualTransportOptionsStructure.mode;
  maxDistance?: number;
  maxDuration?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  minDistance?: number;
  minDuration?: {
    seconds?: number;
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
    minutes?: number;
    xmlschemaType?: {
      namespaceURI?: string;
      localPart?: string;
      prefix?: string;
    };
    sign?: number;
  };
  speed?: number;
};

export namespace IndividualTransportOptionsStructure {
  export enum mode {
    WALK = 'WALK',
    CYCLE = 'CYCLE',
    TAXI = 'TAXI',
    SELF_DRIVE_CAR = 'SELF_DRIVE_CAR',
    OTHERS_DRIVE_CAR = 'OTHERS_DRIVE_CAR',
    MOTORCYCLE = 'MOTORCYCLE',
    TRUCK = 'TRUCK',
  }
}
