/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Directions } from './Directions';
import type { JourneyDetailRef } from './JourneyDetailRef';
import type { Messages } from './Messages';
import type { Notes } from './Notes';
import type { OccupancyType } from './OccupancyType';
import type { ParallelJourneyRefType } from './ParallelJourneyRefType';
import type { ProductType } from './ProductType';
import type { ReferencedJourneyType } from './ReferencedJourneyType';
import type { Stops } from './Stops';

export type Departure = {
  JourneyDetailRef: JourneyDetailRef;
  JourneyStatus?: Departure.JourneyStatus;
  ProductAtStop?: ProductType;
  readonly Product?: Array<ProductType>;
  Notes?: Notes;
  Messages?: Messages;
  Directions?: Directions;
  readonly altId?: Array<string>;
  Stops?: Stops;
  readonly Occupancy?: Array<OccupancyType>;
  readonly ParallelJourneyRef?: Array<ParallelJourneyRefType>;
  readonly referencedJourney?: Array<ReferencedJourneyType>;
  name: string;
  type: string;
  stop: string;
  stopid: string;
  stopExtId?: string;
  prognosisType?: Departure.prognosisType;
  time: string;
  scheduledTimeChanged?: boolean;
  date: string;
  tz?: number;
  track?: string;
  trackHidden?: boolean;
  rtTime?: string;
  rtDate?: string;
  rtTz?: number;
  rtTrack?: string;
  rtTrackHidden?: boolean;
  cancelled?: boolean;
  partCancelled?: boolean;
  reachable?: boolean;
  redirected?: boolean;
  direction?: string;
  directionFlag?: string;
  directionExtId?: string;
  timeAtArrival?: string;
  dateAtArrival?: string;
  rtTimeAtArrival?: string;
  rtDateAtArrival?: string;
  isFastest?: boolean;
  isBorderStop?: boolean;
  isTurningPoint?: boolean;
  entry?: boolean;
  rtCnclDataSourceType?: Departure.rtCnclDataSourceType;
  uncertainDelay?: boolean;
};

export namespace Departure {
  export enum JourneyStatus {
    P = 'P',
    R = 'R',
    A = 'A',
    S = 'S',
  }

  export enum prognosisType {
    PROGNOSED = 'PROGNOSED',
    MANUAL = 'MANUAL',
    REPORTED = 'REPORTED',
    CORRECTED = 'CORRECTED',
    CALCULATED = 'CALCULATED',
  }

  export enum rtCnclDataSourceType {
    DEFAULT = 'DEFAULT',
    VDV = 'VDV',
    HIM = 'HIM',
    HRC = 'HRC',
    SIRI = 'SIRI',
    UIC = 'UIC',
    HRX = 'HRX',
    GTFS = 'GTFS',
    FIS = 'FIS',
    DDS = 'DDS',
    PAISA = 'PAISA',
    FE = 'FE',
    BLACKLIST = 'BLACKLIST',
  }
}
