/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ParticipantRefStructure } from './ParticipantRefStructure';

export type TriasServiceStructure = {
  participantRef?: ParticipantRefStructure;
  serviceAddress?: string;
  serviceId?: string;
  serviceType?: TriasServiceStructure.serviceType;
  serviceUsage?: TriasServiceStructure.serviceUsage;
  version?: string;
};

export namespace TriasServiceStructure {
  export enum serviceType {
    ALERTS = 'ALERTS',
    BOOKING_INFO = 'BOOKING_INFO',
    CONNECTION_DEMAND = 'CONNECTION_DEMAND',
    FACILITIES = 'FACILITIES',
    FARES = 'FARES',
    INDIVIDUAL_ROUTES = 'INDIVIDUAL_ROUTES',
    LOCATIONS = 'LOCATIONS',
    MAPS = 'MAPS',
    POSITIONING = 'POSITIONING',
    SERVICE_REGISTER = 'SERVICE_REGISTER',
    STOP_EVENTS = 'STOP_EVENTS',
    TRIP_INFO = 'TRIP_INFO',
    TRIPS = 'TRIPS',
  }

  export enum serviceUsage {
    CONSUMER = 'CONSUMER',
    PROVIDER = 'PROVIDER',
  }
}
