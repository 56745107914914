/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserNeedStructure = {
  mobilityNeed?: UserNeedStructure.mobilityNeed;
  psychosensoryNeed?: UserNeedStructure.psychosensoryNeed;
  medicalNeed?: UserNeedStructure.medicalNeed;
  encumbranceNeed?: UserNeedStructure.encumbranceNeed;
  excluded?: boolean;
  needRanking?: number;
  extensions?: any;
};

export namespace UserNeedStructure {
  export enum mobilityNeed {
    WHEELCHAIR = 'WHEELCHAIR',
    ASSISTED_WHEELCHAIR = 'ASSISTED_WHEELCHAIR',
    MOTORIZED_WHEELCHAIR = 'MOTORIZED_WHEELCHAIR',
    WALKING_FRAME = 'WALKING_FRAME',
    RESTRICTED_MOBILITY = 'RESTRICTED_MOBILITY',
    OTHER_MOBILITY_NEED = 'OTHER_MOBILITY_NEED',
  }

  export enum psychosensoryNeed {
    VISUAL_IMPAIRMENT = 'VISUAL_IMPAIRMENT',
    AUDITORY_IMPAIRMENT = 'AUDITORY_IMPAIRMENT',
    COGNITIVE_INPUT_IMPAIRMENT = 'COGNITIVE_INPUT_IMPAIRMENT',
    AVERSE_TO_LIFTS = 'AVERSE_TO_LIFTS',
    AVERSE_TO_ESCALATORS = 'AVERSE_TO_ESCALATORS',
    AVERSE_TO_CONFINED_SPACES = 'AVERSE_TO_CONFINED_SPACES',
    AVERSE_TO_CROWDS = 'AVERSE_TO_CROWDS',
    OTHER_PSYCHOSENSORY_NEED = 'OTHER_PSYCHOSENSORY_NEED',
  }

  export enum medicalNeed {
    ALLERGIC = 'ALLERGIC',
    HEART_CONDITION = 'HEART_CONDITION',
    OTHER_MEDICAL_NEED = 'OTHER_MEDICAL_NEED',
  }

  export enum encumbranceNeed {
    LUGGAGE_ENCUMBERED = 'LUGGAGE_ENCUMBERED',
    PUSHCHAIR = 'PUSHCHAIR',
    BAGGAGE_TROLLEY = 'BAGGAGE_TROLLEY',
    OVERSIZE_BAGGAGE = 'OVERSIZE_BAGGAGE',
    GUIDE_DOG = 'GUIDE_DOG',
    OTHER_ANIMAL = 'OTHER_ANIMAL',
    OTHER_ENCUMBRANCE = 'OTHER_ENCUMBRANCE',
  }
}
