import { Typography, Dialog, DialogContent, IconButton, DialogContentText, styled, css } from '@mui/material';

import DiseasesChart from '@/assets/charts/chart-diseases.svg?react';
import DistanceChart from '@/assets/charts/chart-distances.svg?react';
import CloseIcon from '@/assets/icons/close.svg?react';
import { ButtonLink } from '@/components/ButtonLink';
import { useStore } from '@/setup/global-state';
import { transientOptions } from '@/utils/transient-options';

const DialogTypography = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.primary};
    margin-bottom: ${theme.spacing(3)};
  `,
) as typeof Typography;

const DialogTypographySource = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
    color: ${theme.palette.text.secondary};
  `,
);

const ChartWrapper = styled(
  'figure',
  transientOptions,
)<{ $maxWidth: number }>(
  ({ theme, $maxWidth }) => css`
    display: block;
    text-align: center;

    svg {
      display: block;
      max-width: ${theme.spacing($maxWidth)};
      margin: ${theme.spacing(1)} auto 0;
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    right: ${theme.spacing(1)};
    top: ${theme.spacing(1)};
    background-color: ${theme.palette.common.white};

    ${theme.breakpoints.up('sm')} {
      right: ${theme.spacing(4)};
      top: ${theme.spacing(4)};
    }

    ${theme.breakpoints.up('md')} {
      right: ${theme.spacing(8)};
      top: ${theme.spacing(8)};
    }
  `,
);

export function InformationDialog() {
  const dialogOpen = useStore((state) => state.dialogOpen);
  const setDialogOpen = useStore((state) => state.setDialogOpen);

  const onClose = () => setDialogOpen(false);

  return (
    <Dialog open={dialogOpen} maxWidth="xl" onClose={onClose}>
      <DialogContent>
        <StyledIconButton title="Dialog schließen" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
        <DialogContentText component="div">
          <DialogTypography component="h1" fontWeight="bold">
            Berechnungsgrundlagen
          </DialogTypography>
          <DialogTypography>
            In diesem Gesundheitsroutenplaner wird berechnet, welchen Beitrag ein aktiv zurückgelegter Arbeits- oder
            Alltagsweg zur Erreichung der Bewegungsempfehlung leisten kann. Dabei wird eine durchschnittliche
            Gehgeschwindigkeit von 4km/h und 15 km/h für Fahrradstrecken angenommen. Die Steigung wird entsprechend
            berücksichtigt. Aus den Faktoren Verkehrsmittel, Geschwindigkeit, Steigung und Distanz wird der
            Energieumsatz je Zeiteinheit und Route abgeleitet. Die ausgegebenen Werte beziehen sich auf die angegebene
            Zeit für körperliche Aktivität (aktive Bewegung in moderater Intensität) und die angegebene Anzahl der
            durchschnittlichen Strecken-Wiederholung pro Woche.
          </DialogTypography>
          <DialogTypography>
            Die Routenberechnung basiert auf einer Verbindungsauskunft vom nächsten Werktag um 8 Uhr. Um Ihre Route
            detaillierter zu betrachten, verwenden Sie bitte einen{' '}
            <ButtonLink href="https://anachb.vor.at/" target="_blank" rel="noopener">
              ÖV-Routenplaner
            </ButtonLink>
            .
          </DialogTypography>
          <DialogTypography>
            Der Wert „wöchentlich empfohlene Bewegung“ gilt für <u>eine</u> Strecke der jeweiligen Route laut
            WHO-Empfehlung.
          </DialogTypography>
          <DialogTypography>
            Der Wert „CO<sub>2</sub> Ausstoß pro Jahr“ wird in Abhängigkeit der angegebenen Streckenwiederholung
            berechnet. Bei der Risikoreduzierung von Darmkrebs, Diabetes, Herzerkrankung und Schlaganfall wird
            zusätzlich die angegebene durchschnittliche Aktivität pro Woche berücksichtigt.
          </DialogTypography>
          <DialogTypography>
            Die Gesundheitswerte basieren auf wissenschaftlichen Studien, erlauben jedoch keinen Rückschluss auf
            individuelle Gesundheitspotenziale. Besprechen Sie das Thema Bewegung mit Ihrer/Ihrem Ärztin/Arzt um mehr
            über Ihre persönlichen Potenziale zu erfahren.
          </DialogTypography>
          <ChartWrapper $maxWidth={120}>
            <DistanceChart />

            <Typography component="figcaption" textAlign="center">
              Quelle: Martin Loidl (PLUS), angepasst auf Gesundheitsroutenplaner.
            </Typography>
          </ChartWrapper>

          <DialogTypography component="h2" fontWeight="bold">
            Körperliche Aktivität, Mobilität und Gesundheit
          </DialogTypography>
          <DialogTypography>
            Die Wichtigkeit von körperlicher Aktivität für die Gesundheit ist immens. Mehr frühzeitige Todesfälle sind
            auf mangelnde körperliche Aktivität als auf Rauchen zurückgeführt (Lee et al. 2012). „Sitzen ist das neue
            Rauchen“ ist eine häufig zitierte Zusammenfassung der wissenschaftlichen Studienlage. Aktive, also
            muskelbetriebene Mobilität leistet einen wichtigen Beitrag zur Erhöhung der körperlichen Aktivität und damit
            zur Verbesserung der Gesundheit. Arbeits- und Alltagswege bieten ein enormes Potenzial, um den negativen
            Folgen eines sitzenden Lebensstils entgegenzuwirken. Personen, die ihre Arbeitswege mit dem Fahrrad
            zurücklegen, haben - gemäß einer Studie aus Großbritannien mit über 200.000 Probanden - nur ein halb so
            großes Mortalitätsrisiko als Personen, die den Arbeitsweg körperlich inaktiv zurücklegen (Celis-Morales et
            al. 2017). Insgesamt kann das Mortalitätsrisiko durch aktive Pendelmobilität um 9% verringert werden
            (Dutheil et al. 2020).
          </DialogTypography>
          <DialogTypography component="h2" fontWeight="bold">
            Bewegungsempfehlungen
          </DialogTypography>
          <DialogTypography>
            Die Weltgesundheitsorganisation{' '}
            <ButtonLink href="https://www.who.int/" target="_blank" rel="noopener">
              (WHO)
            </ButtonLink>{' '}
            empfiehlt für Erwachsene pro Woche mindestens 150-300 Minuten körperliche Aktivität in moderater Intensität
            oder 75-150 Minuten in hoher Intensität. Das entspricht – je nach Alter und Trainingszustand –
            beispielsweise in flottem Tempo gehen oder im Doppel Tennis zu spielen (mittlere Intensität) bzw. mit
            mindestens 18 km/h Fahrrad fahren, Badminton oder Fußball zu spielen (höhere Intensität). Viele weitere
            Informationen und Beispiele finden Sie dazu in den{' '}
            <ButtonLink
              href="https://fgoe.org/wissenspool_gesunde_lebensweisen_und_klimaschutz"
              target="_blank"
              rel="noopener"
            >
              Österreichischen Bewegungsempfehlungen des Fonds Gesundes Österreich
            </ButtonLink>
            .
          </DialogTypography>
          <DialogTypography component="h2" fontWeight="bold">
            Individuelle Gesundheitseffekte
          </DialogTypography>
          <DialogTypography>
            Welcher konkrete gesundheitliche Nutzen sich aus dem aktiv zurückgelegten Alltagsweg ergeben, kann auf Basis
            der vorhandenen Daten und ohne sportmedizinische Untersuchung nicht gesagt werden. Für so genannte
            prognostische Werte liegen nur Durchschnittswerte vor. Das bedeutet, aus wissenschaftlichen Studien kann
            beispielsweise abgeleitet werden, dass das Risiko für einen Schlaganfall im Durchschnitt um 10% niedriger
            liegt, wenn statt eines inaktiven Lebensstils pro Woche 100 Minuten gejoggt wird (Kyu et al. 2016). Durch
            die Durchschnittsbildung kann aus diesem Wert allerdings nicht rückgeschlossen werden, dass eine konkrete
            Person, die 100 Minuten pro Woche joggt, ein 10% geringeres Schlaganfallrisiko hat. In der Statistik nennt
            man das einen statistischen Fehlschluss. Ein Vergleich aus dem Alltag verdeutlicht noch einmal das Problem:
            Franzis Opa raucht seit seinem 15. Lebensjahr täglich zwei Packungen Zigaretten. Weil der Opa 90 Jahre alt
            wurde, schließt Franzi, dass Rauchen eigentlich nicht schädlich sei. Würde Franzi aber die Biographie von
            10.000 Großvätern kennen, wäre schnell offensichtlich, dass Raucher im Durchschnitt (also inklusive
            Ausreißer nach oben, wie der eigene Opa) weniger alt werden als Nichtraucher.
          </DialogTypography>
          <DialogTypography component="h2" fontWeight="bold">
            Gesundheitsrouting aus Forschungsprojekten
          </DialogTypography>
          <DialogTypography>
            Die wissenschaftlichen Grundlagen für das Gesundheitsrouting wurden im Rahmen der Forschungsprojekte{' '}
            <ButtonLink href="https://gismoproject.wordpress.com/" target="_blank" rel="noopener">
              GISMO
            </ButtonLink>{' '}
            und{' '}
            <ButtonLink href="https://actnow.jetzt/" target="_blank" rel="noopener">
              ActNow
            </ButtonLink>{' '}
            entwickelt. Diese wurden gefördert durch das Bundesministerium für Klimaschutz, Umwelt Energie, Mobilität,
            Innovation und Technologie im Rahmen des Förderprogramms „Mobilität der Zukunft“.
          </DialogTypography>
          <DialogTypography>
            Für die jeweiligen Routen werden die Energieumsätze je Woche, ausgedrückt in metabolischen Äquivalenten je
            Zeiteinheit (MET-Minuten), berechnet. Dafür wird auf ein Standardwerk der Medizin bzw. Sportwissenschaft
            zurückgegriffen (Ainsworth et al. 2011) und ein Mittelwert von gängigen Sport- bzw. Bewegungsarten für die
            auswählbaren Aktivitätstypen gebildet. Zu diesem lebensstilbedingten Grundumsatz wird dann der Energieumsatz
            je Routenempfehlung aus dem Gesundheitsroutenplaner addiert.
          </DialogTypography>
          <DialogTypography component="h2" fontWeight="bold">
            Prognostische Effekte
          </DialogTypography>
          <DialogTypography>
            Der berechnete Gesamtenergieumsatz wird in Risikoprofile für Darmkrebs, Diabetes, Herzerkrankung und
            Schlaganfall übersetzt. Dazu werden die Ergebnisse aus einer der größten Studien zum Verhältnis von
            körperlicher Aktivität und Gesundheitseffekte (Kyu et al. 2016) verwendet. Insgesamt flossen die Daten von
            fast 150 Millionen Personen in diese Untersuchung ein.
          </DialogTypography>
          <ChartWrapper $maxWidth={50}>
            <DiseasesChart />
          </ChartWrapper>
          <DialogTypographySource>
            LEE, I. M., SHIROMA, E. J., LOBELO, F., PUSKA, P., BLAIR, S. N. & KATZMARZYK, P. T. 2012. Effect of physical
            inactivity on major non-communicable diseases worldwide: an analysis of burden of disease and life
            expectancy. The Lancet, 380, 219-229.
          </DialogTypographySource>
          <DialogTypographySource>
            CELIS-MORALES, C. A., LYALL, D. M., WELSH, P., ANDERSON, J., STEELL, L., GUO, Y., MALDONADO, R., MACKAY, D.
            F., PELL, J. P., SATTAR, N. & GILL, J. M. R. 2017. Association between active commuting and incident
            cardiovascular disease, cancer, and mortality: prospective cohort study. BMJ, 357.
          </DialogTypographySource>
          <DialogTypographySource>
            DUTHEIL, F., PÉLANGEON, S., DUCLOS, M., VORILHON, P., MERMILLOD, M., BAKER, J. S., PEREIRA, B. & NAVEL, V.
            2020. Protective Effect on Mortality of Active Commuting to Work: A Systematic Review and Meta-analysis.
            Sports Medicine, 50, 2237-2250.
          </DialogTypographySource>
          <DialogTypographySource>
            FISHMAN, E., SCHEPERS, P. & KAMPHUIS, C. B. M. 2015. Dutch Cycling: Quantifying the Health and Related
            Economic Benefits. American Journal of Public Health, 105, e13-e15.
          </DialogTypographySource>
          <DialogTypographySource>
            KYU, H. H., BACHMAN, V. F., ALEXANDER, L. T., MUMFORD, J. E., AFSHIN, A., ESTEP, K., VEERMAN, J. L.,
            DELWICHE, K., IANNARONE, M. L., MOYER, M. L., CERCY, K., VOS, T., MURRAY, C. J. L. & FOROUZANFAR, M. H.
            2016. Physical activity and risk of breast cancer, colon cancer, diabetes, ischemic heart disease, and
            ischemic stroke events: systematic review and dose-response meta-analysis for the Global Burden of Disease
            Study 2013. BMJ, 354, i3857.
          </DialogTypographySource>
          <DialogTypographySource>
            AINSWORTH, B. E., HASKELL, W. L., HERRMANN, S. D., MECKES, N., BASSETT, D. R. J., TUDOR-LOCKE, C., GREER, J.
            L., VEZINA, J., WHITT-GLOVER, M. C. & LEON, A. S. 2011. 2011 Compendium of Physical Activities: A Second
            Update of Codes and MET Values. Medicine & Science in Sports & Exercise, 43, 1575-1581.
          </DialogTypographySource>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
