/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessibilityAssessmentStructure } from './AccessibilityAssessmentStructure';
import type { ExtensionsStructure } from './ExtensionsStructure';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type FacilityStatusStructure = {
  status: FacilityStatusStructure.status;
  description?: NaturalLanguageStringStructure;
  accessibilityAssessment?: AccessibilityAssessmentStructure;
  extensions?: ExtensionsStructure;
};

export namespace FacilityStatusStructure {
  export enum status {
    UNKNOWN = 'UNKNOWN',
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    PARTIALLY_AVAILABLE = 'PARTIALLY_AVAILABLE',
    ADDED = 'ADDED',
    REMOVED = 'REMOVED',
  }
}
