/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GeneralizedConnectionStructure } from './GeneralizedConnectionStructure';
import type { RecommendationStructure } from './RecommendationStructure';

export type ConnectionStatusStructure = {
  connection: GeneralizedConnectionStructure;
  status: ConnectionStatusStructure.status;
  alternative?: Array<RecommendationStructure>;
};

export namespace ConnectionStatusStructure {
  export enum status {
    UNKNOWN = 'UNKNOWN',
    PLANNED = 'PLANNED',
    CONFIRMED = 'CONFIRMED',
    BROKEN = 'BROKEN',
  }
}
