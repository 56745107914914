/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ExtensionType } from './ExtensionType';
import type { SourceName } from './SourceName';

export type SourceInformation = {
  sourceCountry?: SourceInformation.sourceCountry;
  sourceIdentification?: string;
  sourceName?: SourceName;
  sourceType?: SourceInformation.sourceType;
  reliable?: boolean;
  sourceInformationExtension?: ExtensionType;
};

export namespace SourceInformation {
  export enum sourceCountry {
    AT = 'AT',
    BE = 'BE',
    BG = 'BG',
    CH = 'CH',
    CS = 'CS',
    CY = 'CY',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FI = 'FI',
    FO = 'FO',
    FR = 'FR',
    GB = 'GB',
    GG = 'GG',
    GI = 'GI',
    GR = 'GR',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IM = 'IM',
    IS = 'IS',
    IT = 'IT',
    JE = 'JE',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    LV = 'LV',
    MA = 'MA',
    MC = 'MC',
    MK = 'MK',
    MT = 'MT',
    NL = 'NL',
    NO = 'NO',
    PL = 'PL',
    PT = 'PT',
    RO = 'RO',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    SM = 'SM',
    TR = 'TR',
    VA = 'VA',
    OTHER = 'OTHER',
  }

  export enum sourceType {
    AUTOMOBILE_CLUB_PATROL = 'AUTOMOBILE_CLUB_PATROL',
    CAMERA_OBSERVATION = 'CAMERA_OBSERVATION',
    FREIGHT_VEHICLE_OPERATOR = 'FREIGHT_VEHICLE_OPERATOR',
    INDUCTION_LOOP_MONITORING_STATION = 'INDUCTION_LOOP_MONITORING_STATION',
    INFRARED_MONITORING_STATION = 'INFRARED_MONITORING_STATION',
    MICROWAVE_MONITORING_STATION = 'MICROWAVE_MONITORING_STATION',
    MOBILE_TELEPHONE_CALLER = 'MOBILE_TELEPHONE_CALLER',
    NON_POLICE_EMERGENCY_SERVICE_PATROL = 'NON_POLICE_EMERGENCY_SERVICE_PATROL',
    OTHER_INFORMATION = 'OTHER_INFORMATION',
    OTHER_OFFICIAL_VEHICLE = 'OTHER_OFFICIAL_VEHICLE',
    POLICE_PATROL = 'POLICE_PATROL',
    PRIVATE_BREAKDOWN_SERVICE = 'PRIVATE_BREAKDOWN_SERVICE',
    PUBLIC_AND_PRIVATE_UTILITIES = 'PUBLIC_AND_PRIVATE_UTILITIES',
    REGISTERED_MOTORIST_OBSERVER = 'REGISTERED_MOTORIST_OBSERVER',
    ROAD_AUTHORITIES = 'ROAD_AUTHORITIES',
    ROAD_OPERATOR_PATROL = 'ROAD_OPERATOR_PATROL',
    ROADSIDE_TELEPHONE_CALLER = 'ROADSIDE_TELEPHONE_CALLER',
    SPOTTER_AIRCRAFT = 'SPOTTER_AIRCRAFT',
    TRAFFIC_MONITORING_STATION = 'TRAFFIC_MONITORING_STATION',
    TRANSIT_OPERATOR = 'TRANSIT_OPERATOR',
    VEHICLE_PROBE_MEASUREMENT = 'VEHICLE_PROBE_MEASUREMENT',
    VIDEO_PROCESSING_MONITORING_STATION = 'VIDEO_PROCESSING_MONITORING_STATION',
  }
}
