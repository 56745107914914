/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionDataStructure } from './ActionDataStructure';
import type { BeforeNotices } from './BeforeNotices';
import type { NaturalLanguageStringStructure } from './NaturalLanguageStringStructure';

export type PublishToAlertsActionStructure = {
  actionData?: Array<ActionDataStructure>;
  actionStatus?: PublishToAlertsActionStructure.actionStatus;
  beforeNotices?: BeforeNotices;
  byEmail?: boolean;
  byMobile?: boolean;
  clearNotice?: boolean;
  description?: NaturalLanguageStringStructure;
};

export namespace PublishToAlertsActionStructure {
  export enum actionStatus {
    OPEN = 'OPEN',
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED',
  }
}
