import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { GlobalState } from '@/types/GlobalState';
import { PlaceType } from '@/types/PlaceType';
import { ResultsDisplayType } from '@/types/ResultsDisplayType';

export const useStore = create<GlobalState>()(
  persist(
    (set) => ({
      places: {
        [PlaceType.ORIGIN]: undefined,
        [PlaceType.DESTINATION]: undefined,
      },
      routes: undefined,
      healthEffects: undefined,
      activeMinutesPerWeek: 30,
      routesPerWeek: 4,
      loading: false,
      selectedHealthyRoutingUseCase: undefined,
      resultsDisplayType: ResultsDisplayType.CARDS,
      dialogOpen: false,

      updatePlace: ({ placeType, place }) =>
        set(({ places }) => ({
          places: {
            ...places,
            [placeType]: place,
          },
        })),

      setRoutes: (routes) => set({ routes }),

      setHealthEffects: (healthEffects) => set({ healthEffects }),

      setSelectedHealthyRoutingUseCase: (selectedHealthyRoutingUseCase) => set({ selectedHealthyRoutingUseCase }),

      resetRouting: () =>
        set({
          places: {
            [PlaceType.ORIGIN]: undefined,
            [PlaceType.DESTINATION]: undefined,
          },
          routes: undefined,
          healthEffects: undefined,
          selectedHealthyRoutingUseCase: undefined,
        }),

      setActiveMinutesPerWeek: (activeMinutesPerWeek: number) => set({ activeMinutesPerWeek }),

      setRoutesPerWeek: (routesPerWeek: number) => set({ routesPerWeek }),

      setLoading: (loading) => set({ loading }),

      setResultsDisplayType: (resultsDisplayType) => set({ resultsDisplayType }),

      setDialogOpen: (dialogOpen) => set({ dialogOpen }),
    }),
    {
      name: 'grp.info',
      version: 1,
    },
  ),
);
